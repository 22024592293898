<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
    />
    <vs-popup
      :title="quizConfig.name"
      :active.sync="popupActiveDetails"
    >
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(updateQuizConfig)">
          <vs-col
            vs-type="flex"
            vs-justify="start"
            vs-align="right"
            vs-w="12"
          >
            <vs-row>
              <vs-card
                v-for="(configValue, index ) in quizConfig.value"
                :key="index"
              >
                <vs-row vs-align="center">
                  <vs-col
                    v-if="filter!== 'activation_code'"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="8"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <ValidationProvider
                      name="الخيار"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input
                        placeholder="خيار جديد"
                        v-model="quizConfig.value[index]"
                        class="w-full"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </vs-col>
                  <div v-else>
                    <vs-checkbox
                      class="inline-flex "
                      style="font-size:medium;"
                      v-model="configValue.is_active"
                    >
                      فعال
                    </vs-checkbox>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="8"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <ValidationProvider
                        name="رمز التفعيل"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label>رمز التفعيل</label>
                        <vs-input
                          placeholder="رمز التفعيل"
                          v-model="configValue.activation_code"
                          class="w-full"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="8"
                      vs-sm="4"
                      vs-xs="12"
                    >
                      <ValidationProvider
                        name="تاريخ الانتهاء"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label>تاريخ الانتهاء</label>
                        <vs-input
                          type="date"
                          v-model="configValue.expired_at"
                          class="w-full"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </vs-col>
                  </div>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="2"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-button
                      color="danger"
                      type="gradient"
                      icon-pack="feather"
                      icon="icon-x"
                      @click="deleteValue(index)"
                    />
                  </vs-col>
                </vs-row>
              </vs-card>
              <vs-col
                vs-lg="12"
                class="mb-6"
              >
                <vs-button
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="small"
                  @click="addValue()"
                >
                  إضافة
                </vs-button>
              </vs-col>
            </vs-row>
          </vs-col>
          <hr style="height:1px;border-width:0;background-color:#BDBAC2">
          <div class="vx-row mt-12">
            <div class="vx-col sm:w-1/4 mb-4">
              <vs-button
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(updateQuizConfig)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from '../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../quiz/quiz-repositories/quizRepositoryFactory";

const quizRepo = RepositoryFactory.get('ravenRepository');
export default {
  name: "RavenAdults",
  components: {
    AgTable
  },

  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActiveDetails: false,
      dialogTitle: String,
      quizConfig: {
        id: '',
        name: '',
        value: [{
          activation_code: '',
          is_active: '',
          expired_at: ''

        }],
      },
      filter: ''
    };

  },
  watch: {
    '$route'() {
      this.getType();
      this.fetchAllQuizConfiguration();
    }
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (QuizConfig) {
              self.viewRecordDetails(QuizConfig);
            },
            actions: ['view']
          },
        },
      ];
    },
    handleResponse() {
      this.popupActiveDetails = false;
    },
    updateQuizConfig() {
      let config = this.quizConfig;
      quizRepo.updateQuizConfig(config).then((response) => {
        let index = this.rowData.map((updateQuizConfig) => {
          return updateQuizConfig.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.quizConfig = record;
    },
    fetchAllQuizConfiguration() {
      this.rowData = [];
      quizRepo.fetchAllQuizConfiguration(this.filter).then((response) => {
        response.forEach(item => {
          if (item.key.includes(this.filter))
            this.rowData.push(item);
        });
        this.isLoading = false;
      });
    },
    addValue() {
      if (this.filter === 'activation_code')
        this.quizConfig.value.push({
          activation_code: '',
          is_active: false,
          expired_at: ''

        });
      else
        this.quizConfig.value.push('');
    },
    deleteValue(index) {
      this.quizConfig.value.splice(index, 1);
    },
    getType() {
      if (this.$route.query.type !== undefined)
        this.filter = this.$route.query.type;
    }

  },
  created() {
    this.getType();
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllQuizConfiguration();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>

</style>
