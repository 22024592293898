var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ag-table',{attrs:{"grid-options":_vm.gridOptions,"row-data":_vm.rowData}}),_c('vs-popup',{attrs:{"title":_vm.quizConfig.name,"active":_vm.popupActiveDetails},on:{"update:active":function($event){_vm.popupActiveDetails=$event}}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return handleSubmit(_vm.updateQuizConfig)}}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"start","vs-align":"right","vs-w":"12"}},[_c('vs-row',[_vm._l((_vm.quizConfig.value),function(configValue,index){return _c('vs-card',{key:index},[_c('vs-row',{attrs:{"vs-align":"center"}},[(_vm.filter!== 'activation_code')?_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-lg":"8","vs-sm":"4","vs-xs":"12"}},[_c('ValidationProvider',{attrs:{"name":"الخيار","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"خيار جديد"},model:{value:(_vm.quizConfig.value[index]),callback:function ($$v) {_vm.$set(_vm.quizConfig.value, index, $$v)},expression:"quizConfig.value[index]"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[_c('vs-checkbox',{staticClass:"inline-flex ",staticStyle:{"font-size":"medium"},model:{value:(configValue.is_active),callback:function ($$v) {_vm.$set(configValue, "is_active", $$v)},expression:"configValue.is_active"}},[_vm._v("\n                    فعال\n                  ")]),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-lg":"8","vs-sm":"4","vs-xs":"12"}},[_c('ValidationProvider',{attrs:{"name":"رمز التفعيل","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("رمز التفعيل")]),_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"رمز التفعيل"},model:{value:(configValue.activation_code),callback:function ($$v) {_vm.$set(configValue, "activation_code", $$v)},expression:"configValue.activation_code"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-lg":"8","vs-sm":"4","vs-xs":"12"}},[_c('ValidationProvider',{attrs:{"name":"تاريخ الانتهاء","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("تاريخ الانتهاء")]),_c('vs-input',{staticClass:"w-full",attrs:{"type":"date"},model:{value:(configValue.expired_at),callback:function ($$v) {_vm.$set(configValue, "expired_at", $$v)},expression:"configValue.expired_at"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-lg":"2","vs-sm":"4","vs-xs":"12"}},[_c('vs-button',{attrs:{"color":"danger","type":"gradient","icon-pack":"feather","icon":"icon-x"},on:{"click":function($event){return _vm.deleteValue(index)}}})],1)],1)],1)}),_c('vs-col',{staticClass:"mb-6",attrs:{"vs-lg":"12"}},[_c('vs-button',{attrs:{"color":"primary","type":"filled","icon-pack":"feather","icon":"icon-plus","size":"small"},on:{"click":function($event){return _vm.addValue()}}},[_vm._v("\n                إضافة\n              ")])],1)],2)],1),_c('hr',{staticStyle:{"height":"1px","border-width":"0","background-color":"#BDBAC2"}}),_c('div',{staticClass:"vx-row mt-12"},[_c('div',{staticClass:"vx-col sm:w-1/4 mb-4"},[_c('vs-button',{attrs:{"ycolor":"success","type":"filled"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.updateQuizConfig)}}},[_vm._v("\n              حفظ\n            ")])],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }